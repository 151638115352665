/* Base container */
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    to bottom,
    rgb(var(--navbar-brown)),
    rgba(var(--navbar-brown), 0.7)
  );
  overflow-y: auto;
  padding: 2rem;
  color: white;
}

.containerMobile {
  padding: 1rem;
}

/* Content wrapper */
.contentWrapper {
  flex: 1;
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  gap: 2rem;
}

.containerMobile .contentWrapper {
  gap: 1.5rem;
}

/* Page title */
.pageTitle {
  text-align: center;
  color: white;
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
}

.containerMobile .pageTitle {
  font-size: 1.75rem;
}

/* Search section */
.searchSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.searchBarContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

/* Search input */
.searchInput {
  width: 100%;
  min-height: 60px;
  padding: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.5;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.2);
  color: white;
  transition: all 0.3s ease;
  resize: none;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.searchInput:hover {
  border-color: var(--chair-orange);
}

.searchInput:focus {
  outline: none;
  border-color: var(--chair-orange);
  box-shadow: 0 4px 12px rgba(var(--chair-orange-rgb), 0.2);
}

.containerMobile .searchInput {
  font-size: 1.2rem;
  padding: 1.25rem;
  min-height: 50px;
  border-radius: 20px;
  height: 50px;
}

.extraSmall.searchInput {
  font-size: 1rem;
  padding: 1rem;
  min-height: 45px;
  height: 45px;
}

/* Search actions */
.searchActions {
  display: flex;
  gap: 0.75rem;
  justify-content: flex-end;
}

.searchButton,
.clearButton {
  padding: 0.75rem 1.5rem;
  border-radius: 24px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.searchButton {
  background: var(--chair-orange);
  color: white;
  border: none;
}

.searchButton:hover {
  background: rgba(var(--chair-orange-rgb), 0.9);
  transform: translateY(-2px);
}

.searchButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  transform: none;
}

.clearButton {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.clearButton:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.containerMobile .searchButton,
.containerMobile .clearButton {
  padding: 0.6rem 1.25rem;
  font-size: 0.9rem;
}

/* Connect button */
.connectButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  width: 100%;
  padding: 1rem;
  background: rgba(var(--chair-orange-rgb), 0.8);
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 0.5rem;
}

.connectButton:hover {
  background: var(--chair-orange);
  transform: translateY(-2px);
}

.containerMobile .connectButton {
  padding: 0.75rem;
  font-size: 1rem;
}

/* Tabs container */
.tabsContainer {
  display: flex;
  flex-direction: column;
  background: rgba(var(--navbar-brown), 0.3);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.tabButtons {
  display: flex;
  background: rgba(var(--navbar-brown), 0.4);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobileTabButtons {
  flex-direction: row;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
}

.mobileTabButtons::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.tabButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
  background: transparent;
  border: none;
  border-bottom: 3px solid transparent;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  color: white;
}

.mobileTabButton {
  padding: 0.75rem 1rem;
  font-size: 0.9rem;
}

.tabIcon {
  font-size: 1.25rem;
}

.tabButton:hover {
  color: var(--chair-orange);
}

.activeTab {
  color: var(--chair-orange);
  border-bottom-color: var(--chair-orange);
  background: rgba(var(--navbar-brown), 0.5);
}

.tabContent {
  padding: 1.75rem;
  min-height: 150px;
}

.containerMobile .tabContent {
  padding: 1rem;
}

/* Content list */
.contentList {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.resultSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.resultSection h3 {
  color: white;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  border-left: 3px solid var(--chair-orange);
}

/* Empty states */
.emptyMessage {
  color: var(--text-secondary, rgba(255, 255, 255, 0.7));
  text-align: center;
  font-style: italic;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  opacity: 0.8;
}

.errorMessage {
  color: #ff6b6b;
  text-align: center;
  padding: 2rem 0;
}

/* Loading container */
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 100%;
}

/* Intro section */
.introContainer {
  text-align: center;
  max-width: 600px;
  margin: 2rem auto;
}

.introText {
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
}

.containerMobile .introText {
  font-size: 1rem;
}

/* No results container */
.noResultsContainer {
  width: 100%;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .pageTitle {
    font-size: 1.75rem;
  }

  .searchInput {
    padding: 1.25rem;
    font-size: 1.2rem;
  }

  .tabContent {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .pageTitle {
    font-size: 1.5rem;
  }

  .searchInput {
    padding: 1rem;
    font-size: 1rem;
  }

  .introText {
    font-size: 0.9rem;
  }
}
